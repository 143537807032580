<template>
  <b-modal
    id="modal-gudang"
    hide-header
    hide-footer
    ok-only
    ok-title="Accept"
    centered
    size="lg"
    title="Large Modal"
    class="custom__modal--large"
  >
    <div class="section">
      <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2">
        <b-row>
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <div
              style="cursor: pointer;"
              @click="directPage"
            >
              <feather-icon
                size="24"
                class="text-dark font-weight-bolder"
                icon="ArrowLeftIcon"
              />
              <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1">Kembali</span>
            </div>
          </b-col>
          <b-col
            cols="6"
            class="d-flex justify-content-center align-items-center"
          >
            <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
              Informasi Lokasi SKU
            </h1>
          </b-col>
          <b-col
            cols="3"
            class="text-right"
          >
            <b-button
              class="px-3"
              @click="editUuid == null ? createItem() : updateItem()"
            >
              Simpan
            </b-button>
          </b-col>
        </b-row>
      </header>
      <b-container class="mt-5 pt-5">
        <b-row class="justify-content-center h-100 bg-white pb-5">
          <b-col
            cols="12"
            md="6"
          >
            <b-row>
              <b-col cols="12" class="text-center mb-2">
                <UploadPhoto
                  v-if="!photo_url"
                  :label-for="'upload_photo'"
                  :style-name="'width:195px;height:140px;margin: 0 auto;'"
                  :photo-url-parent="photo_url"
                  @photo="getPhoto"
                />
                <div v-else>
                  <div
                    class="box__upload--photo mx-auto"
                    :style="`background-image: url(${photo_url});`"
                    @click="photo_url = ''"
                  >
                    <b-avatar
                      class="floating__close--button"
                    >
                      <feather-icon
                        class="text-danger"
                        icon="XIcon"
                      />
                    </b-avatar>
                  </div>
                </div>
              </b-col>
              <!-- Nama Gudang -->
              <b-col
                cols="12"
                md="12"
                class="mb-2 custom__form--input"
              >
                <label for="v-nama-gudang">Nama Lokasi SKU <span class="text-danger">*</span></label>
                <b-form-group>
                  <b-form-input
                    id="v-nama-gudang"
                    v-model="formPayload.name"
                    type="text"
                    placeholder="Nama Lokasi SKU"
                    class="custom__input"
                  />
                </b-form-group>
              </b-col>
              <!-- Kota -->
              <b-col
                cols="12"
                md="12"
                class="mb-2 custom__form--input"
              >
                <label for="v-nama-alias">Kota <span class="text-danger">*</span></label>
                <b-form-group>
                  <b-form-input
                    id="v-telp"
                    v-model="formPayload.city"
                    type="text"
                    placeholder="Kota"
                    class="custom__input"
                  />
                </b-form-group>
              </b-col>
              <!-- Alamat -->
              <b-col
                cols="12"
                md="12"
                class="mb-2 custom__form--input"
              >
                <label for="v-alamat">Alamat</label>
                <b-form-group>
                  <b-form-textarea
                    id="v-alamat"
                    v-model="formPayload.address"
                    rows="3"
                    placeholder="Alamat Alias"
                    class="custom__textarea"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </b-modal>
</template>

<script>
/* eslint-disable vue/require-default-prop */
import {
  BModal, BContainer, BRow, BCol, BButton, BFormGroup, BFormInput, BFormTextarea,
} from 'bootstrap-vue'

import UploadPhoto from '@/components/UploadPhoto.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BContainer,
    UploadPhoto,
  },
  props: {
    formData: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: '',
    },
    createItem: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    editId: {
      type: String,
    },
    updateItem: {
      type: Function,
    },
    cleanUpForm: {
      type: Function,
    },
    logo: {
      type: String,
    },
  },
  watch: {
    logo(value) {
      this.photo_url = value
    },
    editId(value) {
      this.editUuid = value
    },
    formData(value) {
      this.formPayload = value
    },
    formPayload: {
      handler(value) {
        this.$emit('getPayload', value)
      },
      deep: true,
    },
  },
  data() {
    return {
      formPayload: {
        name: '',
        description: '',
        logo: '',
      },
      photo_url: '',
      editUuid: null,
    }
  },
  methods: {
    getPhoto(value) {
      this.formPayload.logo = value.photo
    },
    directPage() {
      this.cleanUpForm()
      this.$bvModal.hide('modal-gudang')
    },
  },
}
</script>

<style lang="scss" scoped>
.box__upload--photo {

  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #EFF1F5;
  border: 1px dashed #7A7F94;
  border-radius: 8px;
  position: relative;

  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;

  width: 140px;
  height: 140px;

  .floating__close--button {
    position: absolute;
    top: 6px;
    width: 24px;
    height: 24px;
    right: 6px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
</style>
